import React from 'react'
import GameCard from './GameCard'
import PropTypes from 'prop-types'
import './GameCardGroup.scss'

const GameCardGroup = ({ games, loading, noGamesMessage }) => {
  return (
    <>
      {loading
        ? <div className="game-card-group-text">Loading games...</div>
        : <div className="game-card-group">
          {games && games.length > 0
            ? (
              games.map(game => (
                <GameCard key={game.titleId} game={game}></GameCard>
              ))
            )
            : <div className="game-card-group-text">{noGamesMessage}</div>
          }
        </div>
      }
    </>
  )
}

GameCardGroup.propTypes = {
  games: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  noGamesMessage: PropTypes.string.isRequired
}

export default GameCardGroup
