import {useContext} from 'react'
import {Accordion, AccordionGroup} from '@stillfront/stillfront-component-library'
import UserContext from '../../contexts/UserContext'
import ModalContext from '../../contexts/ModalContext'
import './ManageAccounts.scss'

const ManageAccounts = () => {
  const { userData, loading } = useContext(UserContext)
  const { setModalContent, setModalPayload } = useContext(ModalContext)

  const unlinkGame = ({game, playerId}) => {
    setModalPayload({playerId, titleId: game.titleId})
    setModalContent('confirmUnlink')
  }

  const gamesWithPlayers = userData?.games?.filter((game) => game.playerIds.length > 0)
  const getImageSrc = (game) => game?.images?.someKey ?? 'https://s3-alpha-sig.figma.com/img/f369/23d5/9cb0ee7c87d782f50cef9365d7945728?Expires=1732492800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=aEbqNnSo-PxfvHXvaseqY8pEYfA6PmQAngo30A7tLh7v9JrmDHDIeIRY6NlZ3jBjpd70Og3H4lXbLpRnVXnIcVXljZYKi7Qs0xNsW2hboKr0oWE8MjiClapoZDvHu5WWo2a9sQlGkWxWgddwpFIKKB~x-IktNI3LqLO5pAKM8OEiH6Hi2tH3aLkwfUKdAoJ68-LheWQb7rZrTMk6uBfxpwEgjKlsHq8Oh56vkI~4sXEg7pjDTiV5k7lae1k6jhlQ~0PvXc4uJYdp~nGE2yGijSuvCGfTDUpqTsVGQsKNJcMXrbPBwy0rv0IIRFh2Mn5bWapCTglvdMXtykoYM8chPw__'

  const accordions = gamesWithPlayers?.map((game) => {
    return {
      title: game.title,
      image: { src: getImageSrc(game), alt: game.title },
      children: game.playerIds.map(playerId =>
        <Accordion.Item
          text={playerId}
          buttonText="unlink"
          onClick={unlinkGame}
          key={playerId}
        />
      )
    }
  })

  return (
    <div className="stillfront-content-container manage-account-container">
      <AccordionGroup accordions={accordions} isLoading={loading} />
    </div>
  )
}

export default ManageAccounts
