import React, { createContext, useState } from 'react'
import PropTypes from 'prop-types'

const ModalContext = createContext()

export const ModalProvider = ({ children }) => {
  const [modalContent, setModalContent] = useState(null)
  const [modalPayload, setModalPayload] = useState(null)
  const closeModal = () => {
    setModalContent(null)
    setModalPayload(null)
  }

  return (
    <ModalContext.Provider value={{ modalContent, setModalContent, modalPayload, setModalPayload, closeModal }}>
      {children}
    </ModalContext.Provider>
  )
}

ModalProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ModalContext