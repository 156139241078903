import {Route, Routes} from 'react-router-dom'
import HomeContent from '../../pages/HomeContent/HomeContent'
import AccountSettings from '../../pages/AccountSettings/AccountSettings'
import PrivacyAndSettings from '../../pages/PrivacyAndSettings/PrivacyAndSettings'
import AccountDelete from '../../pages/AccountDelete/AccountDelete'
import ManageAccounts from '../../pages/ManageAccounts/ManageAccounts'
import NavigationBar from '../NavigationBar/NavigationBar'
import PropTypes from 'prop-types'
import './ContentSection.scss'
import TermsAndConditions from '../../pages/TermsAndConditions/TermsAndConditions'

export const ROUTES = {
  HOME: '/',
  ACCOUNT: '/account',
  PRIVACY: '/privacy-and-settings',
  MANAGE_ACCOUNTS: '/manage-accounts',
  DELETE_ACCOUNT: '/delete-account',
  TERMS_AND_CONDITIONS: '/terms-and-conditions'
}

export const ContentSection = ({children}) => {
  return (
    <div className="content-section">
      {children}
      <NavigationBar />
      <Routes>
        <Route path={ROUTES.HOME} element={<HomeContent/>}/>
        <Route path={ROUTES.ACCOUNT} element={<AccountSettings/>}/>
        <Route path={ROUTES.PRIVACY} element={<PrivacyAndSettings/>}/>
        <Route path={ROUTES.MANAGE_ACCOUNTS} element={<ManageAccounts/>}/>
        <Route path={ROUTES.DELETE_ACCOUNT} element={<AccountDelete />}/>
        <Route path={ROUTES.TERMS_AND_CONDITIONS} element={<TermsAndConditions />}/>
      </Routes>
    </div>
  )
}

ContentSection.propTypes = {
  children: PropTypes.node.isRequired,
}