import React from 'react'
import {BannerProvider} from './BannerContext'
import {ModalProvider} from './ModalContext'
import {UserProvider} from './UserContext'
import PropTypes from 'prop-types'

export const Providers = ({children}) => {
  return (
    <UserProvider>
      <BannerProvider>
        <ModalProvider>
          {children}
        </ModalProvider>
      </BannerProvider>
    </UserProvider>
  )
}

Providers.propTypes = {
  children: PropTypes.node.isRequired,
}