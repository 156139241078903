export const gamesMock = [
  {
    titleId: 'abc-def',
    playerIds: ['randomGamerTag#42069', 'LoreWizard#1991'],
    title: 'Albion Online 2 - Orcs and fine dining 🐙',
    images: {
      'someKey': 'https://s3-alpha-sig.figma.com/img/f369/23d5/9cb0ee7c87d782f50cef9365d7945728?Expires=1732492800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=aEbqNnSo-PxfvHXvaseqY8pEYfA6PmQAngo30A7tLh7v9JrmDHDIeIRY6NlZ3jBjpd70Og3H4lXbLpRnVXnIcVXljZYKi7Qs0xNsW2hboKr0oWE8MjiClapoZDvHu5WWo2a9sQlGkWxWgddwpFIKKB~x-IktNI3LqLO5pAKM8OEiH6Hi2tH3aLkwfUKdAoJ68-LheWQb7rZrTMk6uBfxpwEgjKlsHq8Oh56vkI~4sXEg7pjDTiV5k7lae1k6jhlQ~0PvXc4uJYdp~nGE2yGijSuvCGfTDUpqTsVGQsKNJcMXrbPBwy0rv0IIRFh2Mn5bWapCTglvdMXtykoYM8chPw__'
    }
  },
  {
    titleId: 'abc-def2',
    playerIds: ['randomGamerTag#42069', 'LoreWizard#1991'],
    title: 'Water Parks 🐳 - Welcome to the Wetlands',
    images: {
      'someKey': 'https://media.wired.com/photos/5f87340d114b38fa1f8339f9/master/w_1600%2Cc_limit/Ideas_Surprised_Pikachu_HD.jpg'
    }
  },
  {
    titleId: 'abc-def3',
    playerIds: ['randomGamerTag#42069', 'LoreWizard#1991'],
    title: 'Super Marius Kart 8 Deluxe',
    images: {
      'someKey': 'https://media.wired.com/photos/5f87340d114b38fa1f8339f9/master/w_1600%2Cc_limit/Ideas_Surprised_Pikachu_HD.jpg'
    }
  }
]