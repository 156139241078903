import successIcon from '../../assets/images/success.svg'
import warningIcon from '../../assets/images/warning-light.svg'
import './BaseBanner.scss'
import {useContext, useEffect, useState} from 'react'
import {Button} from '@stillfront/stillfront-component-library'
import PropTypes from 'prop-types'
import BannerContext from '../../contexts/BannerContext'

const BaseBanner = ({type, text, handleCallToAction, callToActionText, autoClose}) => {
  const [hideBanner, setHideBanner] = useState(false)
  const { setBannerContent } = useContext(BannerContext)

  useEffect(() => {
    autoClose && setTimeout(() => setHideBanner(true), 5000)
    autoClose && setTimeout(() => setBannerContent(null), 5500)
  }, [])
    
  const icon = type === 'success' ? successIcon : warningIcon

  return (
    <div className="banner-container">
      <div className={`banner ${type}-banner ${hideBanner ? 'hide' : ''}`}>
        <div className="banner-content">
          <img
            src={icon}
            className={`icon ${type}-icon`}
            alt={type}
          />
          <p className="body-1 banner-content-text">{text}</p>
        </div>
        {handleCallToAction && callToActionText && (
          <Button className="banner-action-button" variant="contained" size="small" onClick={handleCallToAction}>{callToActionText}</Button>
        )}
      </div>
    </div>
  )
}

BaseBanner.propTypes = {
  type: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  handleCallToAction: PropTypes.func,
  callToActionText: PropTypes.string,
  autoClose: PropTypes.bool,
}

export default BaseBanner
