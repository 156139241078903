import React from 'react'
import defaultImage from '../../assets/images/mygame-1.svg'
import PropTypes from 'prop-types'
import './GameCard.scss'

const GameCard = ({game}) => {
  const image = game?.images?.someKey ?? defaultImage

  return (
    <div key={game.titleId} className="game-card">
      <img src={image} className="game-card-image" />
      <div className="game-card-title">{game.title}</div>
    </div>
  )
}

GameCard.propTypes = {
  game: PropTypes.object.isRequired,
}

export default GameCard
