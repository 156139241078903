import BaseBanner from '../../appSections/BannerSection/BaseBanner'

const GenericErrorBanner = () => {
  return (
    <BaseBanner
      type="error"
      autoClose={true}
      text="Something went wrong, try again later!"
    />
  )
}

export default GenericErrorBanner
