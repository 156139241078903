import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import warningIcon from '../../assets/images/warning.svg'
import { apiUtils } from '../../utils/apiUtils'
import './AccountDelete.scss'
import UserContext from '../../contexts/UserContext'
import BannerContext from '../../contexts/BannerContext'
import { Button } from '@stillfront/stillfront-component-library'
import {ROUTES} from '../../appSections/ContentSection/ContentSection'

const AccountDelete = () => {
  const { userData, setExpired } = useContext(UserContext)
  const { setBannerContent } = useContext(BannerContext)
  const navigate = useNavigate()

  const handleDelete = async () => {
    apiUtils.deleteAccount()
      .then(() => setExpired(true))
      .catch(error => {
        console.error('Error deleting account:', error)
        setBannerContent('deleteError')
      })
  }

  const handleBack = () => {
    navigate(ROUTES.PRIVACY)
  }

  return (
    <div className="stillfront-content-container account-delete-container">
      <div className="text-container">
        <img
          src={warningIcon}
          className="warning-icon"
          alt="Warning"
        />
        <div className="info-value">
          You are about to delete your account. If you proceed, your account will be deleted within <span className="fw-bold">30 days</span>. You may lose your game progress.
        </div>
      </div>
      <div className="account-delete-button-group">
        <Button variant="delete" className="w-100" onClick={handleDelete} disabled={userData ? userData.expired : false}>
        Delete Account
        </Button>
        <Button variant="contained" className="w-100" onClick={handleBack}>
        Go Back
        </Button>
      </div>
    </div>
  )
}

export default AccountDelete
