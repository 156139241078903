import {useContext} from 'react'
import ModalContext from '../../contexts/ModalContext'
import './ModalSection.scss'
import ConfirmUnlinkModal from '../../components/ConfirmationModal/ConfirmUnlinkModal'

export const ModalSection = () => {
  const activeModalComponent = getModalComponent()

  return <div>{activeModalComponent !== null && activeModalComponent}</div>
}

const getModalComponent = () => {
  const { modalContent, modalPayload } = useContext(ModalContext)

  switch(modalContent) {
    case 'confirmUnlink':
      return <ConfirmUnlinkModal titleId={modalPayload.titleId} playerId={modalPayload.playerId} />
    default:
      return null
  }
}