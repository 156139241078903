export const getThirtyDaysInTheFuture = () => {
  const currentDate = new Date()
  const thirtyDaysLater = new Date(currentDate.setDate(currentDate.getDate() + 30))

  return thirtyDaysLater.toISOString()
}

export const isDateInTheFuture = (timestamp) => {
  const dateToCheck = new Date(timestamp)
  const currentDate = new Date()

  return dateToCheck > currentDate
}

export const getDaysUntilDeletion = (timestamp) => {
  const expirationDateTimestamp = new Date(timestamp).getTime()
  const differenceInMilliseconds = expirationDateTimestamp - new Date().getTime()
  const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24)

  return Math.ceil(differenceInDays)
}