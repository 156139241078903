import { BrowserRouter as Router } from 'react-router-dom'
import Header from './appSections/Header/Header'
import CookieConsent from './components/CookieConsent/CookieConsent'
// @todo get rid of global.scss
import './styles/global.scss'
import './App.scss'
import {BannerSection} from './appSections/BannerSection/BannerSection'
import {ContentSection} from './appSections/ContentSection/ContentSection'
import {ModalSection} from './appSections/ModalSection/ModalSection'
import '@fortawesome/fontawesome-free/css/all.min.css'
import '@stillfront/stillfront-component-library/dist/main.scss'
import {Providers} from './contexts/Providers'

function App() {
  return (
    <Providers>
      <Router>
        <div className="app dark">
          <Header />
          <BannerSection />
          <ContentSection>
            <ModalSection />
          </ContentSection>
          <CookieConsent />
        </div>
      </Router>
    </Providers>
  )
}

export default App
