import {Carousel, Hero} from '@stillfront/stillfront-component-library'
import featuredImg1 from '../../assets/images/featuredGame.jpeg'
import './FeaturedGames.scss'
import {useEffect, useState} from 'react'

function FeaturedGames() {
  const mediaQuery = window.matchMedia('(max-width: 576px)')
  const checkIsMobile = () => mediaQuery.matches
  const [isMobile, setIsMobile] = useState(checkIsMobile())

  useEffect(() => {
    const handleResize = () => setIsMobile(checkIsMobile())
    mediaQuery.addEventListener('change', handleResize)

    // Cleanup listener on unmount
    return () => mediaQuery.removeEventListener('change', handleResize)
  }, [])

  const images = [
    {src: featuredImg1, alt: 'slide 1'},
    {src: featuredImg1, alt: 'slide 2'},
    {src: featuredImg1, alt: 'slide 3'},
    {src: featuredImg1, alt: 'slide 4'},
    {src: featuredImg1, alt: 'slide 5'}
  ]

  return (
    <>
      {isMobile
        ? (
          <Hero
            className="hero"
            imagePosition="left"
            images={images}
            autoplay={true}
            autoplayInterval={5000}
          />
        )
        : (
          <Carousel
            images={images}
            autoplay={true}
            autoplayInterval={5000}
            height="390"
            width="922"
            imagePosition="left"
          />
        )
      }
    </>
  )
}

export default FeaturedGames
