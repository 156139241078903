import React, {useEffect} from 'react'
import './AllGames.scss'
import {apiUtils} from '../../utils/apiUtils'
import GameCardGroup from '../GameCard/GameCardGroup'

const AllGames = () => {
  const [loading, setLoading] = React.useState(true)
  const [games, setGames] = React.useState([])

  useEffect(() => {
    apiUtils
      .getAllTitles()
      .then(response => setGames(response.data.games))
      .catch(error => {
        console.error(error)
        setGames([])
      })
      .finally(() => setLoading(false))
  }, [])

  return (
    <div className="all-games-container">
      <h3>Games</h3>
      <GameCardGroup loading={loading} games={games} noGamesMessage="Error loading games." />
    </div>
  )
}

export default AllGames
