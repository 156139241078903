import FeaturedGames from '../../components/FeaturedGames/FeaturedGames'
import MyGames from '../../components/MyGames/MyGames'
import './HomeContent.scss'
import AllGames from '../../components/AllGames/AllGames'

const HomeContent = () => {
  return (
    <div className="stillfront-container home-content-container">
      <FeaturedGames />
      <MyGames />
      <AllGames />
    </div>
  )
}

export default HomeContent