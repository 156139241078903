import {getThirtyDaysInTheFuture} from '../utils/dateUtils'

export const createUpdateAcceptedMarketing = (setUserData) => (state) => {
  setUserData((prevUserData) => ({
    ...prevUserData,
    acceptedMarketing: state,
  }))
}

export const createUpdateAcceptedNotifications = (setUserData) => (state) => {
  setUserData((prevUserData) => ({
    ...prevUserData,
    acceptedNotifications: state,
  }))
}

export const createRemovePlayerIdFromGames = (setUserData) => (playerId, titleId) => {
  setUserData((prevUserData) => {
    const newGames = prevUserData.games.map((game) =>
      game.titleId === titleId
        ? { ...game, playerIds: game.playerIds.filter((pId) => pId !== playerId) }
        : game
    )
    return { ...prevUserData, games: newGames }
  })
}

export const createSetExpired = (setUserData) => (expired) => {
  setUserData((prevUserData) => ({
    ...prevUserData,
    expirationDate: expired ? getThirtyDaysInTheFuture() : null,
    expired
  }))
}