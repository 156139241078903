import React, { createContext, useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { apiUtils } from '../utils/apiUtils'
import {
  createRemovePlayerIdFromGames,
  createSetExpired,
  createUpdateAcceptedMarketing,
  createUpdateAcceptedNotifications,
} from './UserMutations'

const UserContext = createContext()

export const UserProvider = ({ children }) => {
  const [userData, setUserData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const updateAcceptedMarketing = useCallback(createUpdateAcceptedMarketing(setUserData), [])
  const updateAcceptedNotifications = useCallback(createUpdateAcceptedNotifications(setUserData), [])
  const removePlayerIdFromGames = useCallback(createRemovePlayerIdFromGames(setUserData), [])
  const setExpired = useCallback(createSetExpired(setUserData), [])

  useEffect(() => {
    const fetchUserData = () => {
      apiUtils
        .getUser()
        .then(response => setUserData({...response.data.user, games: response.data.games}))
        .catch(error => setError(error))
        .finally(() => setLoading(false))
    }

    fetchUserData()
  }, [])

  const value = {
    userData,
    loading,
    error,
    removePlayerIdFromGames,
    updateAcceptedMarketing,
    updateAcceptedNotifications,
    setExpired
  }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default UserContext