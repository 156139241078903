import './TermsAndConditions.scss'

const TermsAndConditions = () => {
    return (
        <div className="stillfront-container terms-container">
            <p>THIS TERMS OF USE IS A LEGALLY BINDING AGREEMENT between you and Stillfront Group AB (publ) (<b>“Stillfront”, “we” or “us”</b>). By using the website and/or any website associated with any of our games (<b>“Site”</b>) or any other Stillfront Services, such as our account services (as defined below) to which these terms are applied, you agree to all the terms and conditions of this Terms of Use (“<b>Terms of Use</b>”) and the specific rules applicable to the specific Service. This Terms of Use governs our Site and our apps, services, products, features and content (collectively, the <b>“Service”</b>), whether accessible or downloadable from the Site or third-party app stores or other sources. Whenever you use the Services, you agree to be bound by all of the terms and conditions of these Terms of Service.</p>

            <p className='term-definition'><b>IF YOU DO NOT AGREE TO THESE TERMS OF USE, YOU MAY NOT USE THE SERVICE. USE OF THE SERVICE IS VOID WHERE PROHIBITED.</b></p>
            <p className='term-definition'><b>IMPORTANT NOTICE: FOR U.S. AND CANADIAN PLAYERS, DISPUTES ABOUT THESE TERMS OR RELATING TO OUR SERVICES GENERALLY MUST BE RESOLVED BY BINDING ARBITRATION AND ON AN INDIVIDUAL BASIS ONLY. </b>For more details, see Section 17.</p>

            <h5 className='list-container'>1. Your Acceptance of the Terms of Use</h5>
            <p className='term-definition'>Please carefully read these Terms of Use, which affect your legal rights and obligations. By purchasing a Service and/or registering for an Account or otherwise using the Service, you represent that (1) you are age 16 or older, (2) you understand and agree to these Terms of Use, (3) if you are between the ages of 16 and 18, your legal guardian has reviewed and agrees to these Terms of Use, and (4) you are legally able to conclude contracts in accordance with the age of maturity in relevant jurisdiction(s). By accepting these Terms of Use, you are also consenting to the collection, use and disclosure of your information as described in our Privacy Policy at <a href='https://www.stillfront.com/en/' className='text-white'>https://www.stillfront.com/en/</a> which is incorporated herein by reference in its entirety.</p>

            <h5 className='list-container'>2. Changes to the Terms of Use and the Service</h5>
            <p className='term-definition'>Stillfront reserves the right to change or modify these Terms of Use at any time in its sole discretion. Any such change or modification will be effective immediately upon posting on the Service and your continued use of the Service after any changes or modifications to these Terms of Use are posted will constitute your acceptance of and agreement to such changes or modifications. If you object to any change or modification, your sole recourse shall be to cease using the Service. As an exception, We will give you 60-days’ notice by email, other direct notification forms or through the Service if We change Section 17 on our Agreement to Arbitrate and Class Action Waiver.</p>
            <p className='term-definition'>YOU AGREE THAT STILLFRONT SHALL NOT BE LIABLE TO YOU OR TO ANY THIRD PARTY FOR ANY CHANGE OR MODIFICATION TO THESE TERMS OF USE.</p>
            <p className='term-definition'>Except as may be expressly specified otherwise by Stillfront with respect to paid portions of the Service, Stillfront reserves the right to add, change, suspend or discontinue the Service, or any aspect or feature of the Service, without notice or liability.</p>

            <h5 className='list-container'>3. Relationship to Other Agreements</h5>
            <p className='term-definition'>Your use of the Service or portions of the Service may also be subject to the terms and conditions of one or more related third-party agreements (<b>“Third Party Agreements”</b>), such as agreements governing your download of or access to applications that are part of the Service or accessible through it by means of a third-party website or source. In the case of any conflict between any Third-Party Agreement and these Terms of Use with respect to Stillfront or any aspect of the Service, these Terms of Use will take precedence.</p>

            <h5 className='list-container'>4. Registration, User Data and Accounts</h5>
            <p className='term-definition'>You may register for a user account that enables you to post content and connect to offered game services,to a blog message board, chat room or other forum (an <b>“Account”</b>). You also may be required to otherwise provide information about yourself in order to use the Service. You agree to: (i) provide true, accurate, current and complete information about yourself as prompted by the Service and (ii) maintain and promptly update such data to keep it true, accurate, current and complete. If you provide any information that is untrue, inaccurate, incomplete or not current, Stillfront has the right to suspend or terminate your Account and refuse any and all current or future use of the Service by you.</p>
            <p className='list-container'>Each user of the Service is allowed a maximum of one Account per individual. You may not sell, trade, or otherwise transfer or assign your Account to another party, except as is expressly stated in these Terms of Use. You are responsible for maintaining the confidentiality of your password, chosen security method and Account information and agree not to provide your login information to any other party. You are fully responsible for all activities that occur under your Account with or without your knowledge. You agree to immediately notify Stillfront of any unauthorized use of your Account or any other breach of security.</p>

            <h5 className='list-container'>5. Rights to Use the Service</h5>
            <p className='term-definition'>Subject to your compliance with these Terms of Use, Stillfront grants you a limited, revocable, non-exclusive, non-transferable, non-sublicensable license to use and display, solely for your personal, non-commercial use, e to access the Site and Service unless the system specifically allows it at any given moment, the following: (a) such portions of the Service as are freely accessible from the Stillfront operated Sites or that are, with Stillfront’s authorization, made freely accessible from third party websites, applications, stores or sources, and (b) provided that you have paid the applicable fees and satisfied applicable conditions, such other portions of the Service accessible on a for-payment basis.d. You agree not to (and not to attempt to) (i) use the Service for any use or purpose other than as expressly permitted by these Terms of Use or (ii) copy, adapt, modify, prepare derivative works based upon, derive the source code from, reverse engineer, distribute, license, sell, transfer, publicly display, publicly perform, transmit, stream, broadcast or otherwise exploit the Service or any portion of the Service, except as expressly permitted in these Terms of Use. No licenses or rights are granted to you by implication or otherwise under any intellectual property rights owned or controlled by Stillfront or its licensors, except for the licenses and rights expressly granted in these Terms of Use. Without limiting the generality of the above, Stillfront reserves the right to modify the license and restrictions set forth in this section and elsewhere in these Terms of Use in accordance with the terms and conditions of these Terms of Use, including without limitation by charging fees for such license. Stillfront reserves the right to cease providing service at any time, for any reason.</p>

            <h5 className='list-container'>6. Service Materials, Ownership, Trademarks and Feedback</h5>
            <p className='term-definition'>Except for Your Content (as defined below) and except as otherwise expressly set forth in these Terms of Use, as between you and Stillfront, Stillfront and its licensors own and will retain ownership of all right, title and interest in and to the Service and the Service Materials. “Service Materials” means all information and materials that are part of the Service, including without limitation the following: photographs; graphics; layout; text; images; audio; video; designs; advertising copy; data; logos; domain names; trademarks, service marks, trade names and other source identifiers; any and all copyrightable material, including software; the “look and feel” of the Service or portions thereof; the compilation, assembly and arrangement of the materials of the Service or portions thereof; and User Content. You acknowledge and agree that you shall not acquire any ownership rights whatsoever by downloading Service Materials or by obtaining any Free Virtual Currency or purchasing any Paid Virtual Currency or Virtual Goods (each as defined below).</p>
            <p className='term-definition'>You acknowledge and agree that the Service Materials are protected by, and their use, copying and dissemination may be restricted by, applicable intellectual property and other laws in both Sweden, and other jurisdictions. All rights not expressly granted by these Terms of Use are reserved by Stillfront and its licensors, and no license is granted hereunder by estoppel, implication or otherwise. You agree not to encumber, license, modify, publish, copy, sell, transfer, transmit or in any way exploit, any portion of the Service or Service Materials other than Your Content (as defined below), nor will you attempt to do so, except as expressly permitted in writing by Stillfront and, as applicable, the owner of such Service Materials (from whom you are solely responsible for obtaining permission).</p>
            <p className='term-definition'>The term “Stillfront”, the Stillfront logo and other Stillfront logos and product and service names are trademarks and service marks of, and are owned by, Stillfront. Except as expressly set forth herein, you may not use or display such trademarks in any manner without Stillfront’s prior written permission. All third-party trademarks and service marks appearing on the Service are the property of their respective owners. All rights are reserved therein. Use of any Stillfront trademarks or service marks as “metatags” on other websites is prohibited.</p>
            <p className='term-definition'>If you provide Stillfront with comments, suggestions or feedback about, or in connection with, the Service (collectively, <b>“Feedback”</b>), you agree that such Feedback shall be the exclusive property of Stillfront, and you hereby assign all rights, title and interest in and to such Feedback to Stillfront. You agree that unless otherwise prohibited by applicable law, Stillfront may use, sell, disclose and otherwise exploit the Feedback in any way and for any purpose, without restriction and without compensation to you.</p>

            <h5 className='list-container'>7. User Content</h5>
            <p className='term-definition'>The Service may invite or enable you and other users to create, submit, post, display, transmit, perform, publish or distribute communications, content and materials (including without limitation text, writings, photographs, graphics, images, comments, personally identifiable information and so forth), including by making the foregoing available to Stillfront and other users of the Service, whether via e-mail or through online forums, message boards, messaging services, communication services, blogs or other functionality of the Service or portions thereof (collectively, <b>“User Content”</b>). Stillfront has no obligation to accept, display, review, maintain or otherwise exploit any User Content.</p>
            <p className='term-definition'>You understand that all User Content available in connection with the Service is the sole responsibility of the person from whom such User Content originated. Stillfront has no obligation to pre-screen, review, examine, evaluate or otherwise monitor any User Content for accuracy, completeness, timeliness, validity, legality, decency, quality, integrity, usefulness or any other quality. Stillfront makes no, and hereby disclaims any and all, warranties or other guarantees with respect to User Content. You understand that your use of the Service is at your own risk and that by using the Service, you may be exposed to User Content that is offensive, indecent, objectionable or that does not otherwise meet your needs. You agree that you must evaluate, and bear all risks associated with, the use of any User Content available in connection with the Service. Under no circumstances will Stillfront be liable in any way for any User Content made available via the Service, including, but not limited to, any errors or omissions in any such User Content, or any loss or damage of any kind incurred as a result of the use of such User Content.</p>
            <p className='term-definition'>Notwithstanding the foregoing, Stillfront reserves the right in its sole discretion to pre-screen, review, monitor, refuse, remove from the Service, censor, edit, alter, delete, disable access to or otherwise make unavailable any User Content (including without limitation Your Content) without notice for any reason, including without limitation, any violation of these Terms of Use, Applicable law, Stillfront’s Privacy Policy,  the relevant Game Rules or for no reason, at any time. You may bring User Content that you believe violates these Terms of Use, or other inappropriate user behaviour, to Stillfront’s attention by emailing the game specific customer service or through dedicated Stillfront ID communication channels.</p>

            <h5 className='list-container'>8. Your Content</h5>
            <p className='term-definition'>User Content that you make available in connection with the Service is referred to herein as “Your Content.” You agree that Your Content is not confidential. You further agree that Your Content will not be returned to you.</p>
            <p className='term-definition'>You represent and warrant that Your Content is wholly original to you and that you exclusively own the rights to Your Content, including the right to grant all of the rights and licenses in these Terms of Use without Stillfront incurring any third-party obligations or liability arising out of its exercise of such rights and licenses. Stillfront does not claim any ownership rights in Your Content and, except as expressly set forth herein, nothing in these Terms of Use will be deemed to restrict any rights that you may have to use and exploit Your Content.</p>
            <p className='term-definition'>You hereby grant to Stillfront a worldwide, irrevocable, perpetual, non-exclusive, transferable, royalty-free license (with the right to sublicense), to use, copy, adapt, modify, distribute, license, sell, transfer, publicly display, publicly perform, transmit, stream, broadcast and otherwise exploit Your Content via the Service or otherwise. You also grant to Stillfront the right to sublicense and authorize others to exercise any of the rights granted to Stillfront under these Terms of Use. You further perpetually and irrevocably grant Stillfront the unconditional right to use and exploit your name, persona and likeness included in any User Content and in connection with any User Content, without any obligation to you. Except as prohibited by law, you waive any rights of attribution and/or any so-called moral rights you may have in Your Content, regardless whether Your Content is altered or changed in a manner not agreeable to you.</p>
            <p className='term-definition'>Stillfront has no obligation to monitor or enforce your intellectual property rights in or to Your Content.</p>

            <h5 className='list-container'>9. Restrictions and Rules of Use</h5>
            <p className='term-definition'>You may only register an account and/or use the Service if the use of the Service is legal from where you access it and provided that you have sufficient legal capacity to agree to these Terms of Use.</p>
            <p className='term-definition'>As a condition of your use of the Service, and without limiting your other obligations under these Terms of Use, you agree to comply with the restrictions and rules of use set forth in this section, the Terms of Use, as well as any additional restrictions or rules (such as application-specific rules) set forth in the Service. The Service is for your personal and non-commercial use only. You may not modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, reverse engineer, transfer, or sell for any commercial purposes any portion of the Service, use of the Service, or access to the Service, except for limited reverse engineering activities permitted by applicable law.</p>
            <p className='term-definition'>You agree not to use the Service to transmit, make available, or otherwise promote or support:</p>
            <ul className='term-definition'>
                <li>(a) any User Content that is unlawful, harmful, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, pornographic, sexual, libelous, invasive of another’s privacy, hateful, discriminatory, disparaging, or otherwise objectionable or inappropriate;</li>
                <li>(b) any User Content that promotes illegal activity, such as drug use;</li>
                <li>(c) any User Content that you do not have the right to make available under any law or contractual or fiduciary relationships;</li>
                <li>(d) any User Content that infringes any patent, trademark, trade secret, copyright, or other intellectual property or proprietary rights of any party;</li>
                <li>(e) any unsolicited or unauthorized advertising, promotional materials, “junk mail,” “spam,” “chain letters,” “pyramid schemes,” or any other form of solicitation;</li>
                <li>(f) any spyware, passive collection mechanism, or any other code that acts as a passive or active information collection or transmission mechanism;</li>
                <li>(g) cheats, hacks, cracks, malicious programs, viruses, or other code intended to modify, impair, disrupt, destroy, interfere with, or limit the functionality of the Service or any portion thereof, take control of any computer software, hardware, or telecommunications equipment, or interrupt any user’s uninterrupted use and enjoyment of the Service; or</li>
                <li>(h) unreleased Service content, areas unlocked by hacking, or data not available through normal operation or gameplay on any portion of the Service.</li>
            </ul>
            <p className='term-definition'>You further agree not to:</p>
            <ul className='term-definition'>
                <li>(i) interfere with or disrupt the Service, servers, or networks connected to the Service, or disobey any requirements, procedures, policies, or regulations of networks connected to the Service;</li>
                <li>(ii) interfere with, disrupt, or circumvent any security feature of the Service or any feature that restricts or enforces limitations on the use of or access to the Service;</li>
                <li>(iii) use the Service to intentionally or unintentionally violate any applicable local, state, national, or international law;</li>
                <li>(iv) use IP proxying or other methods to disguise the place of your residence for any purpose, unless such restrictions are not permissible under applicable laws;</li>
                <li>(v) use the Service to harm minors in any way;</li>
                <li>(vi) use the Service to reveal any personal information about another individual without their consent;</li>
                <li>(vii) defraud or mislead Stillfront, its affiliates, partners, or other users or engage in any suspicious activity;</li>
                <li>(viii) engage in any commercial activity using the Service;</li>
                <li>(ix) impersonate any person or entity or misrepresent your affiliation with a person or entity;</li>
                <li>(x) create any account by automated means or under false pretenses, create more than one account per individual, or use any other user’s account for any purpose;</li>
                <li>(xi) cheat or use, develop, or distribute automation software programs or other “cheat utility” software; or</li>
                <li>(xii) sell or transfer the Service, your account, or access to your account for legal tender, currency, or for goods or services of monetary value.</li>
            </ul>
            <p className='term-definition'>You also agree not to access the Service: (x) by any means other than through the interface that is provided by Stillfront for use in accessing the Service; (y) through any automated means (including the use of any script, web crawler, robot, spider, or scraper); or (z) by forging or manipulating identifiers in order to disguise the origin of any access to the Service. Recognizing the global nature of the Internet, you agree to comply with all local rules regarding online conduct and acceptable User Content, and you agree to comply with all applicable laws regarding the transmission of technical data exported to or from the United States, Germany, Sweden, or the country in which you reside.</p>
            <p className='term-definition'>In addition, you agree not to use the Service or any portion of the Service that enables communications, (for example, email, online forums, message boards, messaging services or blogs), to: (1) excessively communicate the same phrase, similar phrases, or gibberish; (2) create undue discontent or disturbances among other users, such as by picking fights, making off-topic posts in forums or insulting other users of the Service; (3) post or transmit non-constructive comments; (4) abuse any functionality enabling reporting to Stillfront by sending false alarms or nonsensical messages; (5) bump forum threads; (6) number threads or post other non-contributory posts, such as IBTL (i.e., in before the lock), TLDR (i.e., “too long; didn’t read'') or “First!” posts; (6) post off-topic comments; (7) commit other actions that Stillfront considers, in its sole discretion, to be “flaming,” “trolling” or “spamming”; (8) create new threads about existing topics or separate threads about an existing topic for further discussion in more than one forum (e.g., cross-postings); or (9) links to threads from other forums unless previously approved by a moderator or Stillfront staff.</p>
            <p className='term-definition'>These rules of use are not meant to be exhaustive, and Stillfront reserves the right to determine what conduct it considers to be in violation of the rules of use or otherwise outside the spirit of the Services and to take action – up to and including termination of your Account and exclusion from further participation in the Services.</p>

            <h5 className='list-container'>10. Free and Paid Virtual Currency and Virtual Goods</h5>
            <p className='term-definition'>The Service may include virtual currency (examples of which are included in the relevant service Rules) provided to you at no charge by Stillfront, earned through game-play or otherwise obtained at no cost to you through game-play or interactions on the Service, the (“<b>Free Virtual Currency</b>”). In addition, you may purchase, for legal tender or actual currency and subject to applicable law, virtual currency, the (“<b>Paid Virtual Currency</b>”). The Service may also include virtual digital items, such as assets, commodities, abilities or other goods (“Virtual Goods”) that may be earned or obtained through the Service or otherwise purchased by you for legal tender or actual currency, or for Free or Paid Virtual Currency, subject to applicable law . Stillfront reserves the right, at any time and at its sole discretion, to manage, regulate, control, modify or eliminate Free and Paid Virtual Currency and/or Virtual Goods, and Stillfront shall have no liability to you or any third party for the exercise of such rights. You shall have a limited, personal, revocable, non-transferable, non-sublicensable license to use solely within the Service in accordance with these Terms of Use any Virtual Goods and Free and Paid Virtual Currency that you have earned, purchased or otherwise obtained in a manner authorized by Stillfront. You have no other right, title or interest in or to any such Virtual Goods or Virtual Currency appearing or originating in the Service.</p>
            <p className='term-definition'>Stillfront prioritizes the use of Free and Paid Virtual Currency in game-play such that any Paid Virtual Currency is first consumed, and secondly, any Free Virtual Currency is then consumed.</p>
            <p className='term-definition'>Stillfront prohibits transfer of Free and Paid Virtual Currency out of your account (e.g., to another user account) except in such cases in which Stillfront provides a feature to do so. Additionally, Stillfront prohibits transfers of accounts between users. You agree not to sublicense, trade, sell or attempt to sell Virtual Goods for “real” money or anything of value outside of a game. Any such transfer or attempted transfer is prohibited and void, and We may terminate your Account because of it.</p>
            <p className='list-container'>You acknowledge that the Virtual Goods can be used solely for entertainment purposes and that any money you pay is solely for entertainment purposes. The Virtual Goods have no cash value and shall not be deemed a thing of value for legal purposes.</p>
            <p className='term-definition'>If a user has not used Virtual Currency, whether Free or Paid, kept in that user’s balance after three (3) months since last use, Stillfront reserves the right to void the Virtual Currency and set the balance to zero (0).</p>
            <p className='term-definition'>You hereby acknowledge and agree that the sale, gift, leasing, sharing, license, or other transfer of Virtual Currency, whether Free or Paid, and Virtual Goods is strictly prohibited except where expressly authorized in the Service. Outside the Service, you shall not sell, redeem or otherwise transfer any Free and Paid Virtual Currency or Virtual Goods to any other user of the Service or any other party.</p>
            <p className='term-definition'>You agree that, to the furthest extent legally permissible, all purchases by you of Paid Virtual Currency and Virtual Goods are final and, except as determined by Stillfront in its sole and absolute discretion, non-refundable. You acknowledge and agree that upon termination of these Terms of Use, your Account or the Service for any reason, including upon Stillfront’s discontinuation of the Service or applicable portion thereof for any reason, all Virtual Currency, whether Free or Paid, and Virtual Goods will be forfeited by you, and, unless required by mandatory applicable law, Stillfront will have no liability to you in connection therewith.</p>


            <h5 className='list-container'>11. Cancellation</h5>
            <p className='term-definition'>This Section 11 applies to purchases of virtual currency and/or items or any other virtual goods or services purchased in connection with the Service (a “<b>Remote Purchase</b>”.) </p>
            <p className='term-definition'>You are entitled to cancel a Remote Purchase within 14 days from the date the Remote Purchase was made without stating any reason for your cancellation. To exercise this right of cancellation, you have to notify us of your decision to cancel a Remote Purchase via a clear statement (e.g., a letter sent by mail, fax, or email) within the specified time frame and you may use the attached sample cancellation form to do so but this is not required. It is sufficient to have sent the notification of your cancellation before the cancellation deadline.</p>
            <p className='term-definition'>In case of a valid cancellation, the benefits received by each party shall be returned and any uses and advantages given shall be surrendered. In cases where a Remote Purchase cannot be returned wholly or partly or only in impaired condition, you will be required to compensate us pro rata to the portion of the Remote Purchase already consumed and/or impaired. We reserve the right to offset any such compensation versus the purchase price for a Remote Purchase that would’ve otherwise been returned (i.e. if you’ve already fully consumed a Remote Purchase, we shall have no obligation to return the purchase price for such Remote Purchase to you). A party’s payment obligations to the other party under this Section 11 shall be settled within 30 days from Stillfront’s receipt of a valid cancellation.</p>
            <p className='term-definition'>Should you make a Remote Purchase for a service (as opposed to virtual goods), your cancellation rights shall expire if we have started to deliver the relevant service to you and you have, in connection with the Remote Purchase, confirmed your understanding that you will forfeit your cancellation rights as soon as we start delivering the relevant service to you.</p>

            <h5 className='list-container'>12. Posting On Other Websites and Linking to the Service</h5>
            <p className='term-definition'>Subject to these Terms of Use, Stillfront agrees to grant you a limited, revocable, non-exclusive, non-transferable license to post your personal Account profile information, such as your achievements in applications that make up part of the Service, and such other Service Materials as Stillfront may authorize in writing, for non-commercial purposes only and only on your personal website or on a third party website that permits posting of such content at the direction of users, provided that such third party website (i) is not a commercial competitor of Stillfront, (ii) does not criticize, or take other actions that could reasonably expected to result in harm to, Stillfront, (iii) does not obtain any rights to such posted content other than a non-exclusive license to post it at your direction, (iv) does not charge for access to such content and does not associate products, services or advertising with such content, and (v) together with all websites to which it links, complies with all applicable laws, does not in any way threaten or violate the intellectual property or other rights of any kind of any third party and does not host, post, publish, distribute, disseminate or facilitate any content that, if User Content posted on the Service, would violate these Terms of Use (each such third party website, an “<b>Authorized Website</b>”). Stillfront agrees to grant you a non-exclusive, limited license, revocable at Stillfront’s discretion, for you to link to Stillfront’s home page from any Authorized Website. You may not display the Service or any portion thereof in frames or “in-line links” without express written permission from Stillfront.</p>

            <h5 className='list-container'>13. Termination</h5>
            <p className='term-definition'>These Terms of Use will remain effective until terminated by either party. You may terminate these Terms of Use at any time and for any reason by (a) uninstalling the Stillfront Service app (or, if you have not installed any Stillfront Service apps, by sending an email to support@Stillfront-games.com notifying Stillfront of your termination), (b) deleting or otherwise destroying all Service-related materials and (c) ceasing use of the Service.</p>
            <p className='term-definition'>Stillfront may terminate these Terms of Use, your Account and your access to the Service (or, at Stillfront’s sole option, applicable portions of the Service) at any time and for any reason. Stillfront may, at its option and in its sole discretion, precede any such termination by issuing you a warning or other notice, such as upon your violation of these Terms of Use. However, you acknowledge that Stillfront is not required to provide you with any such notice or warning prior to any such termination under this Section. In addition, Stillfront may notify authorities or take any actions it deems appropriate (including without limitation suspending your Account and your access to the Service), without notice to you if Stillfront suspects or determines that you may have (i) failed to comply with any provision of these Terms of Use or any policies or rules established by Stillfront; or (ii) engaged in actions relating to or in the course of using the Service that may be illegal or cause liability, harm, embarrassment, harassment, abuse or disruption for you, Stillfront, individuals, any third parties or the Service itself.</p>
            <p className='term-definition'>You may, as the result of termination, lose your Account and all information and data associated therewith, including without limitation your usernames, avatars, in-application characters, progress and achievements, all Free and Paid Virtual Currency and Virtual Goods, as applicable. You acknowledge and agree that upon any termination permitted under these Terms of Use for any reason, whether by you or Stillfront, you will not be entitled to, and Stillfront will not be liable to you or any third party for any refund, reimbursement or other liability. If Stillfront terminates your Account, you may not participate in the Service or any other Stillfront service without Stillfront’s express written permission. Stillfront reserves the right to refuse to keep Accounts for, and provide Stillfront services to, any individual. You shall not allow individuals whose Accounts have been terminated by Stillfront to use your Account.</p>

            <h5 className='list-container'>14. Disputes With Others</h5>
            <p className='term-definition'>You are solely responsible for your interaction with other users of the Service and other parties that you come in contact with through the Service. Stillfront hereby disclaims any and all liability to you or any third party relating to your use of the Service. You will cooperate fully with Stillfront to investigate any suspected unlawful, fraudulent or improper activity, including, without limitation, granting Stillfront access to any password or security-protected portions of your Account. Stillfront reserves the right, but has no obligation, to monitor and manage disputes between you and other users of the Service.</p>

            <h5 className='list-container'>15. Disclaimers of Warranties and Damages, Limitations of Liability</h5>
            <p className='term-definition'>YOU EXPRESSLY AGREE THAT YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK AND THAT THE SERVICE IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS AND WITHOUT WARRANTY OF ANY KIND.</p>
            <p className='term-definition'>THE STILLFRONT PARTIES (AS DEFINED BELOW) HEREBY DISCLAIM ANY AND ALL WARRANTIES, EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, WITH RESPECT TO THE SERVICE, THE SERVICE MATERIALS AND ANY AND ALL USER CONTENT, INCLUDING WITHOUT LIMITATION ANY IMPLIED WARRANTY OF NON-INFRINGEMENT, TITLE, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, THE STILLFRONT PARTIES MAKE NO WARRANTY THAT (a) THE SERVICE WILL MEET YOUR REQUIREMENTS OR THE SERVICE MATERIALS OR USER CONTENT WILL BE VIEWABLE TO YOU; (b) THE SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE OR THAT ERRORS WILL BE CORRECTED; OR (c) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICE OR ANY SERVICE MATERIALS OR USER CONTENT WILL BE ACCURATE OR RELIABLE.</p>
            <p className='term-definition'>THE “STILLFRONT PARTIES” ARE COMPRISED OF STILLFRONT AND ITS AFFILIATES AND SUBSIDIARIES, AND EACH OF THEIR OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, SUPPLIERS AND LICENSORS.</p>
            <p className='term-definition'>UNDER NO CIRCUMSTANCES SHALL THE STILLFRONT PARTIES BE RESPONSIBLE OR LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, PUNITIVE OR OTHER DAMAGES, INCLUDING BUT NOT LIMITED TO LOSS OF REVENUES, LOST PROFITS, LOST DATA OR BUSINESS INTERRUPTION OR OTHER INTANGIBLE LOSSES, ARISING OUT OF OR RELATING IN ANY WAY TO THESE TERMS OF USE OR THE SERVICE, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT ANY STILLFRONT PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. YOUR SOLE REMEDY FOR DISSATISFACTION WITH THE SERVICE IS TO STOP USING THE SERVICE.</p>
            <p className='term-definition'>Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion of liability for certain types of damages. Accordingly, some of the above limitations and disclaimers may not apply to you. To the extent that Stillfront or any other Stillfront Party may not, as a matter of applicable law, disclaim any warranty or limit its liability as set forth herein, the scope and duration of such warranty and the extent of Stillfront and such Stillfront Party’s liability shall be the minimum permitted under such applicable law.</p>

            <h5 className='list-container'>16. Links to External Locations and Third-Party Websites</h5>
            <p className='term-definition'>The Service may contain links to third-party websites or resources. You acknowledge and agree that Stillfront is not responsible or liable for: (i) the availability or accuracy of such websites or resources; or (ii) the content, products, or services on or available from such websites or resources. Links to such websites or resources do not imply any endorsement by Stillfront of such websites or resources or the content, products, or services available from such websites or resources. You acknowledge sole responsibility for and assume all risk arising from your use of any such websites or resources. Stillfront may remove any links at any time for any reason or for no reason.</p>

            <h5 className='list-container'>17. Indemnification</h5>
            <p className='term-definition'>You agree to indemnify, defend and hold the Stillfront Parties harmless from and against any and all claims, liabilities, damages, losses, costs, expenses, fees (including reasonable attorneys’ fees and court costs) made by a third party due to or arising from (1) information in your Account and any information you (or anyone accessing the Service using your password) submit, post or transmit through the Service, (2) your (or anyone accessing the Service using your password) use of the Service, (3) your (or anyone accessing the Service using your password) violation of these Terms of Use or any applicable Additional Terms of Use or Third Party Agreements, and (4) your (or anyone accessing the Service using your password) violation of any rights of any other person or entity.</p>

            <h5 className='list-container'>18. Agreement to Arbitration and Class Action Waiver</h5>
            <p className='term-definition'>If you are a consumer based in the EU or EEA, you may make use of the Online Dispute Resolution platform offered by the European Commission as an out-of-court alternative to resolve disputes. You may also recourse to alternative dispute resolution and refer a dispute to the consumer disputes board or other corresponding out-of-court institution in your domicile.</p>
            <h6 className='list-container'>THIS SECTION of 18 ONLY APPLIES TO PLAYERS IN THE US AND CANADA.</h6>
            <h6 className='list-container'>We Both Agree to Arbitrate</h6>
            <p className='list-container'>If we can’t resolve our dispute through our customer service, <b><i>you and Stillfront both agree to resolve any claims relating to the Terms and/or the Privacy Policy through final and binding arbitration</i></b>. This applies to all kinds of claims under any legal theory, unless the claim fits in one of the exceptions in the Exceptions to Agreement to Arbitrate sub-section. It also applies even after you stopped using your Stillfront account or deleted it.</p>
            <p className='term-definition'>An arbitration proceeding is before a neutral arbitrator instead of a judge and jury, so <b><i>we are both giving up our right to a trial before a judge and jury</i></b>. Arbitrations have different rules than lawsuits in court. They are less formal than lawsuits in courts, and provide limited opportunity to force the other side to share information relevant to the dispute (a process called “discovery”). The arbitrator can award the same damages and relief on an individual basis that a court can award to an individual. But, if any of us does not like the arbitrator’s decision, the courts only have a limited ability to change the outcome of arbitration or make the arbitrator reconsider his or her decision.</p>
            <p className='term-definition'>If we have a dispute about whether this agreement to arbitrate can be enforced or applies to our dispute, we all agree that the arbitrator will decide that, too. In addition, if you or Stillfront brings a claim in court that should be arbitrated or any of us refuses to arbitrate a claim that should be arbitrated, the other of us can ask a court to force us to go to arbitration to resolve the claim (i.e., compel arbitration). You and Stillfront may also ask a court to halt a court proceeding while an arbitration proceeding is ongoing.</p>
            <h6 className='list-container'>The Arbitration Process</h6>
            <p className='list-container'>The American Arbitration Association (AAA) will run the arbitration between you and Stillfront, and AAA’s then-current rules and procedures (including their Supplementary Procedures for Consumer-Related Disputes) will be used. If something in these Terms is different from AAA’s rules and procedures, then we will follow these Terms instead. You can look at AAA’s rules and procedures on their website <a href="http://www.adr.org" className='text-white'>www.adr.org</a> or you can call them at 1-800-778-7879.</p>
            <h6 className='list-container'>To start an arbitration proceeding, use the form on AAA’s website (<a href="http://www.adr.org" className='text-white'>www.adr.org</a>) or call them at 1-800-778-7879.</h6>
            <h6 className='list-container'>Exceptions to Agreement to Arbitrate</h6>

            <p className='term-definition'>We all agree that we will go to court to resolve disputes relating to Your or Stillfront’s intellectual property (for example, trademarks, trade dress, domain names, trade secrets, copyrights or patents.</p>
            <p className='term-definition'>For more information about which court we can go to for resolving these types of disputes, see Section 20 (Venue for Legal Disputes Not Subject to Arbitration).</p>

            <h6 className='list-container'>No Class Actions</h6>
            <h6 className='list-container'>We all agree that we can only bring a claim against each other on an individual basis.</h6>
            <h6 className='list-container'><i>That means:</i></h6>
            <ul>
                <li><h6><i>Neither you nor Stillfront can bring a claim as a plaintiff or class member in a class action, consolidated action or representative action.</i></h6></li>
                <li><h6><i>The arbitrator cannot combine more than one person’s claim into a single case, and cannot preside over any consolidated, class or representative arbitration proceeding (unless we both agree to change this).</i></h6></li>
                <li><h6><i>The arbitrator’s decision or award in one person’s case can only impact the person who brought the claim, not other Stillfront players, and cannot be used to decide other disputes with other players.</i></h6></li>
            </ul>

            <p className='term-definition'>If a court decides that this subsection on “No Class Actions” is not enforceable or valid, then this entire Section (Agreement to Arbitrate and Class Action Waiver US/Canada) will be null and void (i.e., go away). But, the rest of the Terms of Use and Privacy Policy will still apply.</p>
            <h6 className='list-container'>Changes to This Section on Agreement to Arbitrate and Class Action Waiver</h6>
            <p className='term-definition'>We will give you 60-days’ notice by email or through the Service if We propose to change this Section on our Agreement to Arbitrate and Class Action Waiver.</p>



            <h5 className='list-container'>19. Applicable Law</h5>
            <p className='term-definition'>With respect to players in the U.S. and in Canada, the Federal Arbitration Act (including its procedural provisions) is the law that will be applied to determine whether Section (Agreement to Arbitrate and Class Action Waiver) can be enforced and how it should be interpreted.</p>
            <p className='term-definition'><b>You agree that any claim or dispute arising out of or related to these Terms & Conditions, The Privacy Policy and/or the Service shall be governed by the laws of Sweden without regard to its provisions on conflict of laws.</b></p>

            <h5 className='list-container'>20. Venue for legal disputes not subject to arbitration</h5>
            <p className='term-definition'>udicial proceedings (other than small claims actions) that are excluded from the Arbitration Agreement must be brought before the courts of Sweden unless we both agree to some other location or mandatory law provides for the relevant dispute to be brought elsewhere. You and Stillfront both consent to venue and personal jurisdiction in Stockholm, Sweden.</p>

            <h5 className='list-container'>21. Severability</h5>
            <p className='term-definition'>Except as described in the Section under the “No Class Actions” heading, if any part of these Terms or the Privacy Policy is not enforceable, the rest of these Terms of Use and Privacy Policy still applies and is binding and any unenforceable term will be substituted reflecting our intent as closely as possible.</p>

            <h5 className='list-container'>22. Miscellaneous</h5>
            <p className='term-definition'>You agree to be responsible for obtaining and maintaining all telephone, computer hardware, mobile devices and other equipment needed for access to and use of the Service, and all charges related thereto.</p>
            <p className='term-definition'>Stillfront operates and controls the Service from its offices in Sweden. Stillfront makes no representation that the Service is appropriate or available in other locations. The information and materials provided on the Service are not intended for distribution to or use by any person or entity in any jurisdiction where such distribution or use would be contrary to law or regulation or which would subject Stillfront to any registration requirement within such jurisdiction or country. Accordingly, those persons who choose to access the Service from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable.</p>
            <p className='term-definition'>Portions of the Service may be subject to United States export controls. You agree to comply with such export controls, as well as similar such controls in any applicable jurisdiction. Without limiting the foregoing, you agree that no software from the Service may be downloaded, exported or re-exported (a) into (or to a national or resident of) Cuba, Iraq, North Korea, Iran, Syria, Crimea or any other country to which the United States has embargoed goods and/or services; or (b) to anyone on the U.S. Treasury Department’s list of Specially Designated Nationals or the U.S. Commerce Department’s Table of Deny Orders. By downloading any software or applicable portion of the Service, you represent and warrant that you are not located in, under the control of, or a national or resident of, any such country or on any such list.</p>
            <p className='term-definition'>You agree that no joint venture, partnership, employment or agency relationship exists between you and Stillfront as a result of these Terms of Use or your use of the Service. These Terms of Use constitute the entire agreement between you and Stillfront with respect to your use of the Service and any other subject matter hereof and cannot be changed or modified by you except as expressly posted on the Service by Stillfront. The failure of Stillfront to exercise or enforce any right or provision of these Terms of Use shall not constitute a waiver of such right or provision, and no waiver by either party of any breach or default hereunder shall be deemed to be a waiver of any preceding or subsequent breach or default. If any provision of these Terms of Use is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties’ intentions as reflected in the provision, and the other provisions of these Terms of Use shall remain in full force and effect. Neither the course of conduct between the parties nor trade practice will act to modify these Terms of Use. These Terms of Use may not be assigned by you without Stillfront’s prior written consent, but are freely assignable by Stillfront. The section headings used herein are for convenience only and shall not be given any legal import. Upon Stillfront’s request, you will furnish Stillfront any documentation, substantiation or releases necessary to verify your compliance with these Terms of Use. You agree that these Terms of Use will not be construed against Stillfront by virtue of having drafted them. You hereby waive any and all defenses you may have based on the electronic form of these Terms of Use and the lack of signing by the parties hereto to execute these Terms of Use.</p>
            <p className='term-definition'>You agree that the following sections of these Terms of Use will survive any termination of these Terms of Use, your Account or the Service: Section 6 (Service Materials, Ownership, Trademarks and Feedback), Section 8 (Your Content), Section 12 (Termination), Section 13 (Disputes with Others), Section 14 (Disclaimers of Warranties and Damages, Limitations of Liability), Section 16 (Indemnification), Section 18 (Applicable Law) and Section 21 (Miscellaneous).</p>
            
            <h5 className='list-container'>23. Terms Applicable to Apple iOS Users</h5>
            <p className='term-definition'>The following terms are applicable solely if, and to the extent, you use the Services on an Apple iOS device:</p>
            <ul>
                <li className='term-definition'>Acknowledgement: This Terms of Use is concluded between you and us only, and not with Apple LLC (<b>“Apple”</b>). We, not Apple, are solely responsible for the Service and the content thereof.</li>
                <li className='term-definition'>Scope of License: The license granted to you for the Service is limited to a non-transferable license to use the Service on an iOS Product that you own or control and as permitted by the Usage Rules set forth in the App Store Terms of Service.</li>
                <li className='term-definition'>Maintenance and Support: We are solely responsible for providing any maintenance and support services with respect to the Service, as specified in the Terms of Use, or as required under applicable law. Apple has no obligation whatsoever to furnish any maintenance and support services with respect to the Service.</li>
                <li className='term-definition'>Warranty: We are solely responsible for any product warranties, whether expressed or implied by law, to the extent not effectively disclaimed. In the event of any failure of the Service to conform to any applicable warranty, you may notify Apple, and Apple will refund the purchase price for the Service. To the maximum extent permitted by applicable law, Apple will have no other warranty obligation whatsoever with respect to the Service.</li>
                <li className='term-definition'>Product Claims: We, not Apple, are responsible for addressing any claims of you or any third party relating to the Service or your possession and/or use of that Service, including, but not limited to: (i) product liability claims; (ii) any claim that the Service fails to conform to any applicable legal or regulatory requirement; and (iii) claims arising under consumer protection or similar legislation. The Terms of Use does not limit our liability to you beyond what is permitted by applicable law.</li>
                <li className='term-definition'>Intellectual Property Rights: In the event of any third-party claim that the Service or your possession and use of that Service infringes that third party’s intellectual property rights, Apple will not be responsible for the investigation, defense, settlement and discharge of any such intellectual property infringement claim.</li>
                <li className='term-definition'>Legal Compliance: You represent and warrant that (i) you are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist supporting” country; and (ii) you are not listed on any U.S. Government list of prohibited or restricted parties.</li>
                <li className='term-definition'>Developer Contact: Any questions, complaints or claims with respect to the Service should be directed to Stillfront at the email address listed below.</li>
                <li className='term-definition'>Third Party Terms of Agreement: You must comply with applicable third-party terms of agreement when using the Service, e.g., if you use the Service through a social network, you must not be in violation of the social network agreement or terms of use when using the Service.</li>
                <li className='term-definition'>Third Party Beneficiary: Apple, and Apple’s subsidiaries, are third party beneficiaries of the Terms of Use and will have the right (and will be deemed to have accepted the right) to enforce the Terms of Use against you as a third-party beneficiary thereof.</li>
            </ul>
            <p className='term-definition'><b>Contact Us:</b> For all correspondence, please email us at: <a href='mailto:account.support@stillfront.com' className='text-white'>account.support@stillfront.com</a></p>
        </div>
    )
}

export default TermsAndConditions
