import {gamesMock} from './games'

const getTimestamp30DaysInTheFuture = () => {
  const futureTime = new Date().getTime() + (30 * 24 * 60 * 60 * 1000)
  return new Date(futureTime).toISOString()
}

export const userMock = {
  user: {
    sfId: '0f5c5d8b-2d71-4c0f-b0e5-9e81aab4f561',
    userName: 'schnellerLöwe#1947',
    acceptedMarketing: true,
    acceptedNotifications: true,
    creationDate: 0,
    deletionDate: 0,
    expirationDate: getTimestamp30DaysInTheFuture(),
    expired: 1,
    language: 'de_DE',
    reachedRequiredAge: true
  },
  games: gamesMock
}