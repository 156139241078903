import React, {useContext, useEffect, useState} from 'react'
import './MyGames.scss'
import userContext from '../../contexts/UserContext'
import GameCardGroup from '../GameCard/GameCardGroup'

const MyGames = () => {
  const {userData, loading} = useContext(userContext)
  const [games, setGames] = useState([])

  useEffect(() => {
    setGames(
      userData?.games
        ? userData.games
        : []
    )
  }, [userData?.games, loading])

  return (
    <div className="my-games-container">
      <h3>My Games</h3>
      <GameCardGroup loading={loading} games={games} noGamesMessage="Here will be your games as soon as you link them." />
    </div>
  )
}

export default MyGames
