import { Button, Modal } from '@stillfront/stillfront-component-library'
import './ConfirmUnlinkModal.scss'
import PropTypes from 'prop-types'
import {apiUtils} from '../../utils/apiUtils'
import {useContext} from 'react'
import ModalContext from '../../contexts/ModalContext'
import BannerContext from '../../contexts/BannerContext'
import UserContext from '../../contexts/UserContext'

const ConfirmUnlinkModal = ({ playerId, titleId }) => {
  const { closeModal } = useContext(ModalContext)
  const { removePlayerIdFromGames } = useContext(UserContext)
  const { setBannerContent } = useContext(BannerContext)

  const handleUnlink = async () => {
    await apiUtils
      .unlinkGameAccount({playerId, titleId})
      .then(() => {
        removePlayerIdFromGames(playerId, titleId)
        closeModal()
        setBannerContent('unlinkSuccess')
      })
      .catch(() => {
        closeModal()
        setBannerContent('unlinkError')
      })
  }

  const handleCloseModal = () => closeModal(null)

  return (
    <Modal isOpen={true} onClose={() => null}>
      <Modal.Title title="Unlink Game" />
      <Modal.Content>
        <p className="body-2">By unlinking your account, you will lose access to your progress tracking, rewards, and many other perks.</p>
        <p className="body-2">Are you sure you want to unlink <strong>1andOnlyTheGladiator777xP</strong> from <strong>Imperia Online</strong>?</p>
      </Modal.Content>
      <Modal.Footer className="confirmation-modal-footer">
        <Button theme="light" className="confirmation-modal-footer-button" variant="contained" onClick={handleUnlink}>Yes</Button>
        <Button theme="light" className="confirmation-modal-footer-button" variant="outlined" onClick={handleCloseModal}>No</Button>
      </Modal.Footer>
    </Modal>
  )
}

ConfirmUnlinkModal.propTypes = {
  playerId: PropTypes.string.isRequired,
  titleId: PropTypes.string.isRequired,
}

export default ConfirmUnlinkModal
