import { useContext } from 'react'
import { Link } from 'react-router-dom'
import UserContext from '../../contexts/UserContext'
import logo from '../../assets/images/Stillfront-logo.svg'
import profileLogo from '../../assets/images/profile-icon.svg'
import './Header.scss'
import {getCleanUserName} from '../../utils/userNameUtils'

const HeaderLogo = () => {
  return (
    <Link to="/" className="header-logo">
      <img
        src={logo}
        alt="Stillfront logo"
      />
    </Link>
  )
}

const Avatar = () => {
  const { userData } = useContext(UserContext)

  const cleanUserName = userData && userData.userName
    ? getCleanUserName(userData.userName)
    : 'placeholder'

  return (
    <Link to="/account" className="header-avatar">
      <div className="user-title">
        <p className="body-1-bold">{cleanUserName}</p>
      </div>
      <img
        src={profileLogo}
        className="square-logo"
        alt="Profile logo"
      />
    </Link>
  )
}

const Header = () => {
  return (
    <div className="custom-navbar">
      <HeaderLogo />
      <Avatar />
    </div>
  )
}

export default Header
