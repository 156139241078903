import React, { createContext, useState } from 'react'
import PropTypes from 'prop-types'

const BannerContext = createContext()

export const BannerProvider = ({ children }) => {
  const [bannerContent, setBannerContent] = useState(null)

  return (
    <BannerContext.Provider value={{ bannerContent, setBannerContent }}>
      {children}
    </BannerContext.Provider>
  )
}

BannerProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default BannerContext