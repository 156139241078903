import BaseBanner from '../../appSections/BannerSection/BaseBanner'

const RecoverSuccessBanner = () => {
  return (
    <BaseBanner
      type="success"
      autoClose={true}
      text="Your account has been recovered successfully!"
    />
  )
}

export default RecoverSuccessBanner
